import React from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import { WarningTwoIcon } from '@chakra-ui/icons'

const Comments = () => (
  <Box textAlign="center" py={10} px={6}>
    <WarningTwoIcon boxSize="50px" color="orange.300" />
    <Heading as="h2" size="xl" mt={6} mb={2}>
      Comentarios
    </Heading>
    <Text color="gray.500">
      Sitio web en construcción. Estamos en proceso de reactivar la sección de
      comentarios.
    </Text>
  </Box>
)

export default Comments
