import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { Container, Heading, Stack, Text } from '@chakra-ui/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from '@/layout/base'
import SEO from '@/components/SEO'
import Comments from '@/components/Comments'
import { PageContext, MarkdownPostData, MdxPostData } from '@/utils/types'

interface QueryData {
  markdownRemark: MarkdownPostData
  mdx: MdxPostData
}

interface Props extends PageProps {
  pageContext: PageContext
  data: QueryData
}

const PostTemplate = ({ pageContext, data }: Props) => (
  <Layout>
    <SEO
      title={data.mdx.frontmatter.title}
      description="Trisquete.com es un blog sobre finanzas personales."
      canonical={`https://trisquete.com/${pageContext.slug}/`}
      index={!data.mdx.frontmatter.noindex}
    />
    <Container maxW="5xl">
      <Stack
        textAlign="center"
        align="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 5, md: 10 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight="110%"
        >
          {data.mdx.frontmatter.title.substring(
            0,
            data.mdx.frontmatter.title.length / 2
          )}
          <Text as="span" color="orange.400">
            {data.mdx.frontmatter.title.substring(
              data.mdx.frontmatter.title.length / 2
            )}
          </Text>
        </Heading>
        {/* <Text color="gray.500" maxW="3xl">
          {pageContext.slug}
        </Text> */}
      </Stack>
    </Container>
    <Container maxW="5xl">
      <MDXRenderer>{data.mdx.body}</MDXRenderer>
    </Container>
    <Container maxW="5xl" pb={20}>
      <Comments />
    </Container>
  </Layout>
)

export default PostTemplate

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    # markdownRemark(fields: { slug: { eq: $slug } }) {
    #   html
    #   timeToRead
    #   excerpt
    #   frontmatter {
    #     title
    #     cover {
    #       publicURL
    #     }
    #     coverAlt
    #     description
    #     datePublished
    #     dateModified
    #     category
    #     tags
    #     slug
    #   }
    #   fields {
    #     slug
    #     date
    #   }
    # }
    mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      excerpt
      frontmatter {
        title
        cover {
          publicURL
        }
        coverAlt
        description
        datePublished
        dateModified
        category
        tags
        slug
        noindex
      }
      fields {
        slug
        date
      }
    }
  }
`
